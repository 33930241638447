import useLocalizedCategoriesFeatureEnabled from '@aurora/shared-client/components/community/useLocalizedCategoriesFeatureEnabled';
import CommunityMaintenancePlaceholder from '../../../components/community/CommunityMaintenancePlaceholder/CommunityMaintenancePlaceholder';
import React, { useContext } from 'react';
import type { NextSeoProps } from 'next-seo';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { useNodePageHeaderText } from '../../../components/community/NodeBannerWidget/useNodePageHeaderText';
import BasePage from '../../../components/page/BasePage/BasePage';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';

/**
 * Community Page
 *
 * @author Adam Ayres
 */
const CommunityPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    community: { seoTitle, description: seoDescription }
  } = useContext(AppContext);
  const { loading, description } = useNodePageHeaderText(!!seoDescription);

  const { loading: policyLoading, enabled: isLocalizedCategoriesEnabled } =
    useLocalizedCategoriesFeatureEnabled(module);

  if (loading || policyLoading) {
    return null;
  }

  const nextSeoProps: NextSeoProps = {
    title: seoTitle,
    description: seoDescription || description,
    noindex: isLocalizedCategoriesEnabled
  };

  if (isLocalizedCategoriesEnabled) {
    return <CommunityMaintenancePlaceholder />;
  }

  return <BasePage pageId={EndUserPages.CommunityPage} nextSeoProps={nextSeoProps} />;
};

export default CommunityPage;
