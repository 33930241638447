import { useContext } from 'react';
import type { QueryResult } from '@apollo/client';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { ContextNodeFragment } from '@aurora/shared-generated/types/graphql-types';
import { getLog } from '@aurora/shared-utils/log';
import type {
  GroupHubDetailsQuery,
  GroupHubDetailsQueryVariables
} from '../../types/graphql-types';
import groupHubDetailsQuery from './GroupHubDetails.query.graphql';

const log = getLog(module);

export interface GrouphubDetailsQueryData {
  /**
   * Whether the category data is loaded or not
   */
  loading: boolean;
  /**
   * Merged context node and board details data
   */
  grouphubData: Pick<GroupHubDetailsQuery, 'groupHub'> & Pick<ContextNodeFragment, 'description'>;
  /**
   * Query result
   */
  queryResult: QueryResult<GroupHubDetailsQuery, GroupHubDetailsQueryVariables>;
}

/**
 * This hook is used to fetch details of a groupHub
 * @param variables the variables that fetch fields conditionally from the GQL call
 * @param skip A boolean to indicate whether the query should be called or not
 * @returns QueryResult of type GroupHubDetailsQuery
 *
 * @author Akash Goenka
 */
export default function useGroupHubDetails(
  variables: GroupHubDetailsQueryVariables,
  skip = false
): GrouphubDetailsQueryData {
  const { contextNode } = useContext(AppContext);
  const queryResult = useQueryWithTracing<GroupHubDetailsQuery, GroupHubDetailsQueryVariables>(
    module,
    groupHubDetailsQuery,
    { variables, skip }
  );

  if (queryResult?.loading) {
    return {
      loading: true,
      grouphubData: null,
      queryResult
    };
  }

  if (queryResult?.error) {
    log.error(queryResult?.error, 'Error getting grouphub details for %O', variables);
  }

  const grouphubData = {
    ...queryResult?.data?.groupHub,
    description: contextNode.description
  };

  return {
    loading: false,
    grouphubData,
    queryResult
  };
}
